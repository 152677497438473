import {useGeHolidaysTableQuery, useGetDefaultComparisonDatesQuery} from "../../store";
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {
    Cohort, CurrentUser,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType,
    Holiday,
    HolidayEvent,
    LocationOptions,
    Message
} from "../../types";
import {Loading} from "../Common/Loading/Loading";
import {AlertMessage, Icon, Input, Modal, Tooltip} from "@vacasa/react-components-lib"
import {VirtualizedTable} from "../Common/VirtualizedTable/VirtualizedTable";
import React, {ReactElement, useEffect, useState} from "react";
import {HolidayModal} from "./HolidayModal";
import {HolidayEventModal} from "./HolidayEventModal";
import {Checkbox} from '@material-ui/core';
import {UiUtils} from "../../utils";


interface HolidayProps {
    isFetchingCohorts: boolean;
    cohortData: Cohort[];
    currentUser: CurrentUser;
    locationOptions: LocationOptions;
    isFetchingLocationOptions: boolean;
}

type HolidayTableComponent = (props: HolidayProps) => ReactElement<any, any> | null;

export const HolidaysTable: HolidayTableComponent = (props) => {
    const {
        isFetchingCohorts,
        cohortData,
        currentUser,
        locationOptions,
        isFetchingLocationOptions,
    } = props;
    const {data: defaultComparisonDates, isFetching: isFetchingDefaultCDs} = useGetDefaultComparisonDatesQuery();
    const {refetch, data, isFetching} = useGeHolidaysTableQuery();
    const [updatedData, setUpdatedData] = useState<Holiday[]>();
    const [additionalData, setAdditionalData] = useState<Holiday[]>();
    const [uiAlert, setUiAlert] = useState<Message | null>({type: "success", content: "Table is in-sync"});
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedHoliday, setSelectedHoliday] = useState<Holiday>();
    const [selectedHolidayEvent, setSelectedHolidayEvent] = useState<HolidayEvent>();
    const [formType, setFormType] = useState<DemandInfluenceFormType>();
    const [openHolidayModal, setOpenHolidayModal] = useState<boolean>(false);
    const [openHolidayEventModal, setOpenHolidayEventModal] = useState<boolean>(false);
    const [filteredHolidays, setFilteredHolidays] = useState<Holiday[]>([]);
    const [hideInactive, setHideInactive] = useState<boolean>(true);
    const [hideExpired, setHideExpired] = useState<boolean>(true);
    const [openModalAfterRefresh, setOpenModalAfterRefresh] = useState<boolean>(false);

    const newHoliday = () => {
        setSelectedHoliday(null);
        setFormType(DemandInfluenceFormOptions.ADD);
        setOpenHolidayModal(true);
    }

    const onUpdateHoliday = (holidayEvent: HolidayEvent) => {
        let updatedHoliday = {...selectedHoliday}
        updatedHoliday.holiday_events = selectedHoliday.holiday_events
            .map(event => event.id === holidayEvent.id ? holidayEvent : event);
        const updatedHolidays = updatedData.map(holiday => holiday.id === selectedHoliday.id ? updatedHoliday : holiday)
        setUpdatedData(updatedHolidays)
    }

    const openEventEditModal = (id: number, formType: DemandInfluenceFormOptions) => {
        const matchingHolidays = data.filter(h => h.holiday_events.map(he => he.id).indexOf(id) >= 0);
        if (matchingHolidays.length === 1) {
            const matchingHoliday = matchingHolidays[0];
            setSelectedHoliday(matchingHoliday);
            setFormType(formType);
            setSelectedHolidayEvent(matchingHoliday.holiday_events.filter(he => he.id === id)[0]);
            setOpenHolidayEventModal(true);
        }
    }

    useEffect(() => {
        if (!isFetching && data !== undefined) {
            setUpdatedData(data);
        }
    }, [data, isFetching]);

    useEffect(() => {
        if (!updatedData) return;

        let addedData: Holiday[] = [];

        updatedData.forEach((h) => {
            let rowCopy = {...h};
            let holidayEvents: HolidayEvent[] = [];
            h.holiday_events.forEach((he, idx) => {
                let eventCopy = {...he};

                let geographyTooltip = "";

                if (!!he.super_regions) geographyTooltip += `Super Regions: ${he.super_regions};`
                if (!!he.states) geographyTooltip += `States: ${he.states};`
                if (!!he.regions) geographyTooltip += `Regions: ${he.regions};`
                if (!!he.cohorts) geographyTooltip += `Cohorts: ${he.cohorts};`
                if (!!he.unit_code_prefixes) geographyTooltip += `Unitcode Prefixes: ${he.unit_code_prefixes};`
                if (!!he.ops_markets) geographyTooltip += `Ops Markets: ${he.ops_markets};`

                eventCopy["similar_geography"] = "";
                eventCopy["geography_tooltip"] = geographyTooltip;

                [
                    {field: "Super Regions", column: "super_regions"},
                    {field: "States", column: "states"},
                    {field: "Regions", column: "regions"},
                    {field: "Cohorts", column: "cohorts"},
                    {field: "Unitcode Prefixes", column: "unit_code_prefixes"},
                    {field: "OPS Markets", column: "ops_markets"},
                ].forEach(d => {
                    const common = UiUtils.parseCSVField(d.field, h[d.column] ?? "")
                    if (common.length > 0) {
                        eventCopy["similar_geography"] = common;
                        return;
                    }
                    else if ((he[d.column] ?? "") !== "" && he[d.column].split(",").length > 0) {
                        eventCopy.similar_geography = `${he[d.column].split(",").length} ${d.field}`
                        return;
                    }
                });

                let minDate: Date, maxDate: Date, minComparisonDate: Date, maxComparisonDate: Date;

                if (he.comparison_dates.length > 0) {
                    he.comparison_dates.forEach(hes => {
                        const date = new Date(hes.date);
                        const compareDate = new Date(hes.comparison_date);
                        if (!minDate) {
                            minDate = date;
                            maxDate = date;
                            minComparisonDate = compareDate;
                            maxComparisonDate = compareDate;
                        }
                        if (date < minDate) minDate = date;
                        if (date > maxDate) maxDate = date;
                        if (compareDate < minComparisonDate) minComparisonDate = compareDate;
                        if (compareDate > maxComparisonDate) maxComparisonDate = compareDate;
                    });

                    eventCopy.date_range = `${he.comparison_dates.length} dates: ${minDate.toISOString().split("T")[0]} to ${maxDate.toISOString().split("T")[0]}`;
                    eventCopy.comparison_date_range = `${he.comparison_dates.length} dates: ${minComparisonDate.toISOString().split("T")[0]} to ${maxComparisonDate.toISOString().split("T")[0]}`;
                }
                else {
                    eventCopy.date_range = "No dates provided";
                    eventCopy.comparison_date_range = "No dates provided";
                }

                holidayEvents.push(eventCopy);
            })
            rowCopy.holiday_events = holidayEvents;

            addedData.push(rowCopy);
        });

        setAdditionalData(addedData)

        if (openModalAfterRefresh) {
            let sortedHolidays = [...data];
            sortedHolidays.sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));

            if (sortedHolidays.length > 0) {
                setSelectedHoliday(sortedHolidays[0])
                setSelectedHolidayEvent(null);
                setOpenHolidayEventModal(true);
            }
            setOpenModalAfterRefresh(false);
        }
    }, [updatedData]);
    
    useEffect(() => {
        if (!!additionalData) {
            let filteredHolidays: Holiday[] = [];
            additionalData.forEach(h => {
                let holidayCopy = {...h}
                holidayCopy.holiday_events = h.holiday_events.filter(he =>
                    //(hideExpired ? new Date(he.end_date) < today : true) &&
                    (hideInactive ? he.active : true)
                )
                if (hideInactive || hideExpired) {
                    if (holidayCopy.holiday_events.length > 0) {
                        filteredHolidays.push(holidayCopy)
                    }
                }
                else {
                    filteredHolidays.push(holidayCopy)
                }
            });

            searchValue.toLowerCase().split(" ").forEach(sv => {
                filteredHolidays = filteredHolidays.filter((h: Holiday) => {
                    return (
                        h.id.toString().includes(sv) ||
                        h.name.toLowerCase().toString().includes(sv) ||
                        h.creator_email.toLowerCase().includes(sv) ||
                        h.holiday_events.filter(t =>
                            t.title.toLowerCase().includes(sv) ||
                            t.address?.toLowerCase().includes(sv)
                        ).length > 0
                    );
                })
            });

            setFilteredHolidays(filteredHolidays);            
        }
    }, [searchValue, additionalData, hideExpired, hideInactive])

    const builder = new DataSourceBuilder<Holiday>();
    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.5,
        },
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Name',
        field: 'name',
        displayConfiguration: {justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Category',
        field: 'category',
        displayConfiguration: {justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Events',
        field: 'event_count',
        displayConfiguration: {
            justifyContent: 'center',
            flexGrow: 0.5,
        },
        func: (row) => (
            <>{
                row.holiday_events.length
            }</>
        )
    });
    builder.addColumn({
        label: 'Description',
        field: 'description',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 2
        }
    });
    builder.addColumn({
        label: 'Creator',
        field: 'creator_email',
        displayConfiguration: {justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Actions',
        field: 'actions',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
        func: (row) => (
            <div className="action-button-list">
                <Tooltip message="Edit dates">
                    <>
                    <div className="action-button action-button-icon"
                         onClick={() => {
                             setFormType(DemandInfluenceFormOptions.EDIT);
                             setSelectedHoliday(row);
                             setOpenHolidayModal(true);
                         }}>
                        <Icon.Edit3 height={24} width={24}/>
                    </div>
                    <div className="action-button action-button-icon"
                         onClick={() => {
                             setFormType(DemandInfluenceFormOptions.ADD);
                             setSelectedHoliday(row);
                             setSelectedHolidayEvent(null);
                             setOpenHolidayEventModal(true);
                         }}>
                        <Icon.PlusSquare height={24} width={24}/>
                        Add Event
                    </div>
                    </>
                </Tooltip>
            </div>
        )
    })

    builder.setSortable({field: 'id', order: 'asc'});
    builder.addPagination({remote: false});
    builder.setFilterHeader({
        options: []
    });


    return (
        <div>
            <div className="table-utility-row">
                <div className="unit-alert-container">
                {uiAlert && (
                        <AlertMessage
                            customClass="alert-message"
                            text={uiAlert?.content}
                            type={uiAlert?.type}
                            height="small"
                        />
                    )}
                </div>
                <div className="unit-search-input">
                    <label htmlFor="unit-search">Search:</label>
                    <Input
                        customClass="unit-search"
                        type="text"
                        value={searchValue}
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                </div>
                <Tooltip message="Hide inactive rules">
                    <div className="rebl-input-group">
                        <Checkbox
                            checked={hideInactive}
                            onChange={() => setHideInactive(!hideInactive)}
                            className="filter-cb"
                            id="inactive-cb"
                        />
                        <label htmlFor="inactive-cb">Hide Inactive</label>
                    </div>
                </Tooltip>
                <Tooltip message="Hide expired rules">
                    <div className="rebl-input-group">
                        <Checkbox
                            checked={hideExpired}
                            onChange={() => setHideExpired(!hideExpired)}
                            className="filter-cb"
                            id="expired-cb"
                        />
                        <label htmlFor="expired-cb">Hide Expired</label>
                    </div>
                </Tooltip>
                <div className="refetch-icon">
                    <Tooltip message="Refresh Holidayss">
                        <Icon.RefreshCCW className="pointer" height={24} width={24} onClick={() => {
                            refetch();
                            setUiAlert({type: "success", content: "Table is in-sync"});
                        }}/>
                    </Tooltip>
                    <Tooltip message="Add Holiday">
                         <span className="demand-influence-icon" onClick={newHoliday}>
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className="table-list">
                <div>
                    {isFetching ?
                        <Loading className="table-list-loading"/>
                        :
                        <VirtualizedTable
                            className="generic-table"
                            dataSource={builder.build(filteredHolidays)}
                            onRowChange={() => null}
                            headerOptions={{height: 60}}
                            isHolidayAccordion={{
                                handleUiAlert: (message: Message) => setUiAlert(message)
                            }}
                            openModal={openEventEditModal}
                        />
                    }
                </div>
            </div>
            <div className="modal">
                <Modal
                    canExit={true}
                    showModal={openHolidayModal}
                    setShowModal={setOpenHolidayModal}
                    size='small'
                >
                    <HolidayModal
                        closeModal={(refresh?: boolean, newHoliday?: boolean) => {
                            setOpenHolidayModal(false);
                            if (newHoliday) setOpenModalAfterRefresh(true);
                            if (refresh === true) refetch();
                        }}
                        formType={formType}
                        selectedHoliday={selectedHoliday}
                        allHolidays={data}
                        isFetchingHolidays={isFetching}
                    />
                </Modal>
                <Modal
                    canExit={true}
                    showModal={openHolidayEventModal}
                    setShowModal={setOpenHolidayEventModal}
                    size='large'
                >
                    <HolidayEventModal
                        currentUser={currentUser}
                        closeModal={(refresh: boolean) => {
                            setOpenHolidayEventModal(false);
                            if (refresh === true) refetch();
                        }}
                        formType={formType}
                        selectedHoliday={selectedHoliday}
                        selectedHolidayEvent={selectedHolidayEvent}
                        isFetchingHolidays={isFetching}
                        locationOptions={locationOptions}
                        isFetchingLocationOptions={isFetchingLocationOptions}
                        allCohorts={cohortData}
                        isFetchingCohorts={isFetchingCohorts}
                        defaultComparisonDates={defaultComparisonDates}
                        isFetchingDefaultCDs={isFetchingDefaultCDs}
                        onUpdateHoliday={onUpdateHoliday}
                    />
                </Modal>
            </div>
        </div>
    );
}