import React, {KeyboardEvent, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as _ from 'lodash';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    Column,
    ColumnProps,
    defaultTableRowRenderer,
    Index,
    Table,
    TableCellProps,
    TableHeaderProps,
    TableRowProps
} from 'react-virtualized';
import {
    ActionColumn as DataSourceActionColumn,
    Column as DataSourceColumn,
    DataSource,
    FilterField,
    OrderField,
    Row,
} from '../../../types/VirtualizedTable';
import {Checkbox, TablePagination} from '@material-ui/core';
import './VirtualizedTable.scss';
import {Cell} from './Cell';
import {Header} from './Header';
import {DataSourceBuilder} from './DataSourceBuilder';
import {useTaskQueue} from '../../../hooks/useTaskQueue';
import {DemandInfluenceAccordion, HolidayAccordion, Loading, ReblAccordion, UnitAccordion, UnitTetherAccordion} from "../../index";
import {DemandInfluenceFormType, Message} from "../../../types";

interface VirtualizedTableProps<T> {
    dataSource: DataSource<T>;
    onRowChange: (column: string, rowData: Row<T>) => void;
    initialSelectedRows?: number[];
    onSelectedChange?: (selectedIds: number[]) => void;
    className?: string;
    onValidChange?: (isValid: boolean) => void;
    disabled?: boolean;
    sortSelected?: boolean;
    isAccordion?: {
        handleUiAlert: (message: Message) => void;
    };
    isHolidayAccordion?: {
        handleUiAlert: (message: Message) => void;
    };
    isUnitAccordion?: {
        handleUiAlert: (message: Message) => void;
    };
    isUnitTetherAccordion?: {
        handleUiAlert: (message: Message) => void;
    };
    isReblAccordion?: {
        handleUiAlert: (message: Message) => void;
    }
    headerOptions?: {
        height?: number;
    };
    pageSize?: number;
    openModal?: (id: number, formType: DemandInfluenceFormType) => void;
}

interface ExtraHeight {
    [index:number] : number
}

type VirtualizedTableComponent = <T>(props: VirtualizedTableProps<T>) => ReactElement<any, any> | null;

export const VirtualizedTable: VirtualizedTableComponent = (props) => {
    const {
        dataSource,
        onRowChange,
        initialSelectedRows,
        onSelectedChange,
        className,
        onValidChange,
        disabled,
        sortSelected,
        headerOptions,
        isAccordion,
        isHolidayAccordion,
        isUnitAccordion,
        isUnitTetherAccordion,
        isReblAccordion,
        pageSize,
        openModal,
    } = props;
    const { pagination, sortable } = dataSource;
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set(initialSelectedRows));
    const [filters, setFilters] = useState<FilterField[]>(dataSource.filterConfig?.initialFilters || []);
    const [order, setOrder] = useState<OrderField>(sortable);
    const [rows, setRows] = useState<Row<any>[]>([]);
    const invalidCells = new Set([]);
    const [paging, setPaging] = useState<{ size: number; number: number }>({ number: 1, size: pageSize ? pageSize : 10 });
    const { addTask } = useTaskQueue({ shouldProcess: true });
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [originalValues, setOriginalValues] = useState<{ [key: string]: any } | null>(null);
    const headerHeight = headerOptions?.height ?? 50;
    const [extraHeight, setExtraHeight] = useState<ExtraHeight>({});
    // a change in this constant shouldn't render the component again
    // so this is not a component's state
    const editableCellRefs = useRef<{ [key: string]: any }>({});
    const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);
    const [numOfSelected, setNumOfSelected] = useState<number>(selectedRows.size); // ?.size;

    const applyFilters = useCallback((rows: Row<any>[]): Row<any>[] => {
        if (_.isEmpty(filters)) {
            return rows;
        }

        const getFilterForColumn = (column: string) => {
            for (const filter of filters) {
                if (filter.field === column) {
                    return filter;
                }
            }
            return null;
        };

        const passesFilters = (row: Row<any>) => {
            const columns = _.keys(row);

            for (const column of columns) {
                const value = row[column];
                const filterForField = getFilterForColumn(column);

                const isEmptyFilter = _.isNull(filterForField) || _.isUndefined(filterForField);
                // const isEmptyValue = _.isNull(value) || _.isUndefined(value) || value === '';

                if (isEmptyFilter) { //  || isEmptyValue) {
                    continue;
                }
                let includes: boolean = true;
                switch (filterForField.type) {
                    case 'number':
                    case 'text':
                        includes =  `${value.toString().toLowerCase()}`.includes(filterForField.value.toLowerCase());
                        break;
                    case 'select':
                        includes = _.toLower(`${value}`).startsWith(_.toLower(filterForField.value));
                        break;
                    case 'range':
                        const range = filterForField.value.split(',');
                        includes = +value >= +range[0] && +value <= +range[1];
                        break;
                    case 'boolean':
                        includes = value as boolean === (filterForField.value.toLowerCase() === "true");
                        break;
                    default:
                        break;
                }

                if (!includes) {
                    return false;
                }
            }

            return true;
        };

        return _.filter(rows, (r) => passesFilters(r));
    }, [filters]);

    const paginate = useCallback((rows: Row<any>[]): Row<any>[] => {
        const { number: pageNumber, size: pageSize } = paging;
        return rows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }, [paging]);

    const sort = useCallback((rows: Row<any>[]): Row<any>[] => {
        const { field } = order;
        let newOrder: Row<any>[] = rows.sort((a, b) => {
            if (_.isNaN(+a[field])) {
                return sortString(a[field], b[field], order.order);
            }
            return sortNumber(a[field], b[field], order.order);
        });

        if (sortSelected) {
            newOrder = newOrder.sort((a, b) => Number(selectedRows.has(b.id)) - Number(selectedRows.has(a.id)))
        }

        return newOrder;

    }, [order]);

    const updatePage = useCallback(async (number, size, filters: FilterField[], order: OrderField): Promise<void> => {
        try {
            setIsLoading(true);

            const { result, count } = await dataSource?.pagination.function(number, size, filters, order);

            const updatedRows = DataSourceBuilder.toRows(result);
            console.log(`updating rows in page`, { paging, filters });
            setRows(updatedRows);
            setCount(count);
        } catch (e) {
            console.log('error calling pagination method', { error: e });
        } finally {
            setIsLoading(false);
        }
    }, [dataSource?.pagination, paging]);

    useLayoutEffect(() => {
        return function cleanup() {
            setSelectedRows(new Set([]));
        };
    }, []);

    // Runs everytime the data changes or the user changes pages
    useEffect(() => {
        if (!pagination && !sortable) {
            return;
        }

        if (pagination && pagination.remote) {
            addTask({ method: updatePage, params: [paging.number, paging.size, filters, order] });
            return;
        }

        let rows = applyFilters(dataSource.rows);
        setCount(rows.length);
        if (sortable) {
            rows = sort(rows);
        }
        if (pagination) {
            rows = paginate(rows);
        }
        setRows(rows);
    }, [paging, filters, order, addTask, dataSource.rows, applyFilters, paginate, pagination, sort, sortable, updatePage]);

    useEffect(() => {
        if (pagination || sortable) {
            return;
        }

        const filteredRows = applyFilters(dataSource.rows);
        setRows(filteredRows);
        setCount(filteredRows.length);
    }, [dataSource.rows, filters, applyFilters, pagination, sortable]);

    useEffect(() => {
        onValidChange && onValidChange(!(invalidCells.size > 0));
    }, [invalidCells.size, rows, onValidChange]);

    useEffect(() => {
        if (rows.length === 0 || !_.isNull(originalValues)) {
            return;
        }
        const originalRowValuesByKey = _.reduce(
            rows,
            (acc, row) => {
                acc[row.id] = _.reduce(
                    row,
                    (acc, value, key) => {
                        if (key === `key`) {
                            return acc;
                        }
                        acc[key] = value;
                        return acc;
                    },
                    {}
                );
                return acc;
            },
            {}
        );
        setOriginalValues(originalRowValuesByKey);
    }, [rows, originalValues]);

    const sortNumber = (a: number, b: number, order: string) => {
        if (order === 'asc') {
            return a - b;
        }
        return b - a;
    };

    const sortString = (a: string, b: string, order: string) => {
        if (order === 'asc') {
            return a.localeCompare(b);
        }
        return a.localeCompare(b) * -1;
    };

    const getRow = ({ index }: Index) => {
        return rows[index];
    };

    const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        let selected: number[] = [];

        if (e.target.checked) {
            selected = _.map(rows, (row) => row.id);
            selected = selected.filter((row) => isWorkableDate(row));
        }
        setSelectedRows(new Set(selected));
        onSelectedChange(selected);
    };

    useEffect(() => {
        setNumOfSelected(selectedRows.size);
    }, [selectedRows]);

    const onSelectSingle = (id: number) => {
        let newSelected: Set<number> = new Set(Array.from(selectedRows));
        if (newSelected.has(id)) {
            newSelected.delete(id);
        } else {
            newSelected.add(id);
        }
        setSelectedRows(new Set(newSelected));
        onSelectedChange(Array.from(newSelected));
    };

    const onFilterChange = (filters: FilterField[]) => {
        setPaging((page) => ({ number: 1, size: page.size }));
        setFilters(filters);
    };

    const onOrderChange = (newOrder: OrderField) => {
        setPaging((page) => ({ number: 1, size: page.size }));
        setOrder(newOrder);
    };

    const renderHeader = (props: TableHeaderProps, column: DataSourceColumn | DataSourceActionColumn) => {
        const filtering = _.isEmpty(dataSource.filterConfig)
            ? undefined
            : {
                config: dataSource.filterConfig,
                onFilterChange,
                filters,
            };

        const ordering = _.isEmpty(sortable)
            ? undefined
            : {
                orderBy: order,
                onOrderChange,
            };

        return <Header column={column} filtering={filtering} ordering={ordering} />;
    };
    const handleCellValidState = (id: string, isValid: boolean) => {
        if (!isValid) {
            invalidCells.add(id);
            return;
        }
        invalidCells.delete(id);
    };

    const getHeight = (index:number): number => {
        const height = extraHeight[index];
        if(_.isNil(height)){
            rowCache.set(index, 0, 20, 50);
            return 50;
        }
        rowCache.set(index, 0, 20, height + 50);
        return height + 50;
    }

    const rowCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 37, // tune as estimate for unmeasured rows
        minHeight: 10,     // keep this <= any actual row height
    });

    let rowParent = null;

    const handleAccordionChange = (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? id : false);
        const rowPixels = (
            isUnitTetherAccordion
                ?
                rows[index].tethers.length
                :
            isHolidayAccordion
                ?
                rows[index].holiday_events.length
                :
            1
        ) * 48;
        if (isExpanded) {
            setExtraHeight({[index]: (51 + rowPixels)});
        }
        else{
            const exists = extraHeight[index];
            if(!_.isNil(exists)){
                setExtraHeight({[index]:undefined});
            }
        }
    };

    useEffect(() =>{
        setPaging({ number: 1, size: paging.size });
    },[dataSource])


    useEffect(() =>{
        setExpandedAccordion(expandedAccordion ?? false);
        setExtraHeight(expandedAccordion ? extraHeight : {});
    },[paging])

    const renderRow = (props: TableRowProps) => {
        if (isLoading) {
            return null;
        }
        return isAccordion || isHolidayAccordion || isUnitAccordion || isReblAccordion || isUnitTetherAccordion ?
            <CellMeasurer
                cache={rowCache}
                columnIndex={0}
                key={props.key}
                parent={rowParent}
                rowIndex={props.index}
            >
                {
                    isUnitAccordion ?
                        <UnitAccordion tableProps={props} expanded={expandedAccordion} handleChange={handleAccordionChange} key={JSON.stringify(props.rowData)}/>
                    : isHolidayAccordion ?
                        <HolidayAccordion
                            tableProps={props}
                            expanded={expandedAccordion}
                            handleChange={handleAccordionChange}
                            key={JSON.stringify(props.rowData)}
                            openModal={openModal}
                        />
                    : isReblAccordion ?
                        <ReblAccordion tableProps={props} expanded={expandedAccordion} handleChange={handleAccordionChange} key={JSON.stringify(props.rowData)}/>
                    : isUnitTetherAccordion ?
                        <UnitTetherAccordion tableProps={props} expanded={expandedAccordion} handleChange={handleAccordionChange} key={JSON.stringify(props.rowData)}/>
                    :
                    <DemandInfluenceAccordion tableProps={props}  expanded={expandedAccordion} handleChange={handleAccordionChange} key={JSON.stringify(props.rowData)}/>
                }
            </CellMeasurer>
            :
            defaultTableRowRenderer(props);
    };

    const isWorkableDate = (date) => {
        return true;
        // if (_.isNil(date) || !isValid(parse(date, DATE_FORMAT, new Date()))) {
        //     return true;
        // }
        // return isAfter(new Date(date), startOfYesterday());
    };

    const cellCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 50, // keep this <= any actual row height
        minHeight: 10,     // keep this <= any actual row height
    });

    const cellParent = { // an intermediary between measured row cells
        //   and their true containing Grid
        invalidateCellSizeAfterRender: ({rowIndex}) => {
            if (rowParent &&
                typeof rowParent.invalidateCellSizeAfterRender === 'function') {
                rowParent.invalidateCellSizeAfterRender({columnIndex: 0, rowIndex});
            }
        },
    }

    const renderCell = (cellProps: TableCellProps, column: DataSourceColumn) => {
        rowParent = cellProps.parent;
        return (
            <CellMeasurer
                cache={cellCache}
                columnIndex={cellProps.columnIndex}
                parent={cellParent}
                rowIndex={cellProps.rowIndex}
            >
                <Cell
                    column={column}
                    cellProps={cellProps}
                    onRowChange={onRowChange}
                    onRepeat={(dataKey, value) => {
                        dataSource.copyHandler(dataKey as any, value, workableRows);
                    }}
                    onValidChange={(isValid) => handleCellValidState(`${column.field}${cellProps.rowIndex}${cellProps.columnIndex}`, isValid)}
                    ref={column.editable ? setReferenceEditableColumn(cellProps.rowIndex, cellProps.columnIndex) : undefined}
                    onKeyDown={handleKeyDown}
                    disabled={!isWorkableDate(cellProps.rowData.date) || disabled}
                    originalValues={(originalValues || {})[cellProps.rowData.id] || {}}
                />
            </CellMeasurer>
        );
    };

    const setReferenceEditableColumn = (rowIndex: number, columnIndex: number) => {
        return (input) => {
            editableCellRefs.current[`${rowIndex},${columnIndex}`] = input;
        };
    };

    const handleKeyDown = (event: KeyboardEvent, rowIndex: number, columnIndex: number): void => {
        switch (event.key) {
            case 'ArrowDown':
            case 'Enter':
                if (cellExists(rowIndex + 1, columnIndex)) setFocus(rowIndex + 1, columnIndex);
                break;
            case 'ArrowUp':
                if (cellExists(rowIndex - 1, columnIndex)) setFocus(rowIndex - 1, columnIndex);
                break;
        }
    };

    const setFocus = (rowIndex: number, columnIndex: number): void => {
        editableCellRefs.current[`${rowIndex},${columnIndex}`].focus();
    };
    const cellExists = (rowIndex: number, columnIndex: number): boolean => {
        return editableCellRefs.current[`${rowIndex},${columnIndex}`];
    };

    const renderActionCell = (cellProps: TableCellProps, column: DataSourceActionColumn) => {
        const { rowData } = cellProps;
        return <span className="virtualized-table-cell">{column.func(rowData)}</span>;
    };
    const renderColumnWithFunc = (cellProps: TableCellProps, column: DataSourceColumn) => {
        const { rowData } = cellProps;
        return <span className="virtualized-table-cell">{column.func(rowData)}</span>;
    };

    const numOfRows = rows.length;
    const workableRows = rows.filter((row) => isWorkableDate(row.date));
    const numOfWorkableRows = workableRows.length;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPaging((page) => ({ size: page.size, number: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const size = value > 0 ? value : paging.size;
        setPaging(() => ({ number: 1, size: size }));
    };

    return (
        <div className={className ?? ''}>
            <div>
                <div className={`virtualized-table ${paging.size >= 10 ? 'virtualized-table-standard' : 'virtualized-table-short'}`}>
                    <React.Fragment>
                        <AutoSizer>
                            {(props) => {
                                const { height, width } = props;
                                return (
                                    <Table
                                        rowCount={numOfRows}
                                        height={height}
                                        width={width}
                                        headerHeight={headerHeight}
                                        rowHeight={(index)=>getHeight(index.index)}
                                        rowGetter={getRow}
                                        rowRenderer={(props) => renderRow(props)}>
                                        {onSelectedChange && (
                                            <Column
                                                dataKey="select-checkbox"
                                                disableSort
                                                width={48}
                                                className="virtualized-table-column"
                                                headerRenderer={() => (
                                                    <Checkbox
                                                        indeterminate={numOfSelected > 0 && numOfSelected < numOfWorkableRows}
                                                        checked={numOfWorkableRows > 0 && numOfSelected === numOfWorkableRows}
                                                        onChange={onSelectAll}
                                                        className="virtualized-table-checkbox"
                                                    />
                                                )}
                                                cellRenderer={({ rowData }) => (
                                                    <Checkbox
                                                        checked={selectedRows.has(rowData.id)}
                                                        onChange={() => onSelectSingle(parseInt(rowData.id))}
                                                        className="virtualized-table-checkbox"
                                                        disabled={!isWorkableDate(rowData.date)}
                                                    />
                                                )}
                                            />
                                        )}
                                        {_.map(dataSource.columns, (column) => (
                                            <Column
                                                key={column.label}
                                                label={column.label}
                                                dataKey={column.field}
                                                width={1}
                                                flexGrow={column.displayConfiguration?.flexGrow ?? 1}
                                                className={`virtualized-table-column ${
                                                    column.label === 'Unit Code' ? 'RowColumnUnitCode' : ''
                                                }`}
                                                cellRenderer={
                                                    !column.func
                                                        ? (props: TableCellProps) => renderCell(props, column)
                                                        : (props: TableCellProps) => renderColumnWithFunc(props, column)
                                                }
                                                headerRenderer={(props: ColumnProps) => renderHeader(props, column)}
                                            />
                                        ))}

                                        {_.map(dataSource.actionColumns, (column) => (
                                            <Column
                                                key={column.label}
                                                label={column.label}
                                                dataKey={column.label}
                                                width={1}
                                                flexGrow={column.displayConfiguration?.flexGrow ?? 1}
                                                className="virtualized-table-column"
                                                cellRenderer={(props: TableCellProps) => renderActionCell(props, column)}
                                                headerRenderer={(props: TableHeaderProps) => renderHeader(props, column)}
                                            />
                                        ))}
                                    </Table>
                                );
                            }}
                        </AutoSizer>
                        {isLoading && (
                            <div className="virtualized-table-loader">
                                <Loading />
                            </div>
                        )}
                    </React.Fragment>
                </div>
                {!_.isEmpty(pagination) && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={count}
                        rowsPerPage={paging.size}
                        page={paging.number - 1}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </div>
        </div>
    );
};
