import React, {ReactElement, useEffect, useState} from 'react';
import {BulkSidePanel, Loading, VirtualizedTable} from '../index';
import './ReblRulesetList.scss';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {
    Cohort,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType, LocationOptions,
    Message,
    ReblRuleset
} from "../../types";
import {AlertMessage, Icon, Input, Modal, Tooltip, withTooltip} from '@vacasa/react-components-lib';
import {isEmpty, orderBy} from "lodash";
import {useGetAllReblRulesQuery} from "../../store";
import {ReblRulesetModal} from "./ReblRulesetModal";
import {Checkbox} from '@material-ui/core';
import {UiUtils} from "../../utils";


interface ReblRulesetListProps {
    isFetchingCohorts: boolean;
    cohortData: Cohort[];
    locationOptions: LocationOptions
    isFetchingLocationOptions: boolean;
    // currentUser: CurrentUser;
}

type ReblRulesetListComponent = (props: ReblRulesetListProps) => ReactElement<any, any> | null;

export const ReblRulesetList: ReblRulesetListComponent = (props) => {
    const {
        isFetchingCohorts,
        isFetchingLocationOptions,
        cohortData,
        locationOptions,
        // currentUser
    } = props;
    const {refetch, data: reblRules, isFetching: isFetchingReblRules} = useGetAllReblRulesQuery();

    const [reblRuleData, setReblRuleData] = useState<ReblRuleset[]>([]);
    const [additionalReblRuleInfo, setAdditionalReblRuleInfo] = useState<ReblRuleset[]>([]);
    const [filteredReblRules, setFilteredReblRules] = useState<ReblRuleset[]>([]);

    const [searchValue, setSearchValue] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedReblRule, setSelectedReblRule] = useState<ReblRuleset>();
    const [isShowingSave, setIsShowingSave] = useState(false);
    // const [filterOption, setFilterOption] = useState<string>("unassigned");
    const [hideExpired, setHideExpired] = useState<boolean>(true);
    const [uiAlert, setUiAlert] = useState<Message | null>({type: "success", content: "Table is in-sync"});
    const [formType, setFormType] = useState<DemandInfluenceFormType>();

    const selectReblRule = (reblRule: ReblRuleset) => {
        setSelectedReblRule(reblRule);
        setFormType(DemandInfluenceFormOptions.EDIT)
        setOpenModal(true);
    }

    const newReblRule = () => {
        setSelectedReblRule(null);
        setFormType(DemandInfluenceFormOptions.ADD);
        setOpenModal(true);
    }
    const copyReblRule = (reblRule: ReblRuleset) => {
        setSelectedReblRule(reblRule);
        setFormType(DemandInfluenceFormOptions.COPY);
        setOpenModal(true);
    }
    const handleSave = (message: Message) => {
        setUiAlert(message);
        setIsShowingSave(false);
    }

    const onUpdateReblRule = (updatedReblRule: ReblRuleset) => {
        const updatedReblRules = filteredReblRules.map(
            reblRule => reblRule.id === updatedReblRule.id ? updatedReblRule : reblRule
        );
        setFilteredReblRules(updatedReblRules);
    }

    useEffect(() => {
        if (!isEmpty(reblRules)) {
            setReblRuleData(() => {
                let copy: ReblRuleset[] = [];
                reblRules.forEach((rr: ReblRuleset) => {
                    let rowCopy = {...rr};
                    rowCopy.extra_data = {
                        adjustment_type: rr.adjustment_type,
                        created: rr.created_at,
                        season: rr.season,
                        flag: rr.flag,
                        action: rr.action,
                    }
                    copy.push(rowCopy);
                })
                return copy;
            });
        }
    }, [reblRules, isFetchingReblRules])

    useEffect(() => {
        if (!isFetchingReblRules) {
            let addedData: ReblRuleset[] = [];
            orderBy(reblRuleData, "id").forEach(r => {
                let rowCopy = {...r};
                let searchGeography = "";
                if (!!r.super_regions) searchGeography += `Super Regions: ${r.super_regions};`
                if (!!r.state) searchGeography += `States: ${r.state};`
                if (!!r.region) searchGeography += `Regions: ${r.region};`
                if (!!r.cohort_values) searchGeography += `Cohorts: ${r.cohort_values};`
                if (!!r.unit_code_prefix) searchGeography += `Unitcode Prefixes: ${r.unit_code_prefix};`
                if (!!r.ops_markets) searchGeography += `Ops Markets: ${r.ops_markets};`

                rowCopy["cohorts"] = r.cohort_values.split(",").length;
                rowCopy["similar_geography"] = "";
                rowCopy["search_geography"] = searchGeography;

                [
                    {field: "Super Regions", column: "super_regions"},
                    {field: "States", column: "state"},
                    {field: "Regions", column: "region"},
                    {field: "Cohorts", column: "cohort_values"},
                    {field: "Unitcode Prefixes", column: "unit_code_prefix"},
                    {field: "OPS Markets", column: "ops_markets"},
                ].forEach(d => {
                    const common = UiUtils.parseCSVField(d.field, r[d.column])
                    if (common.length > 0) {
                        rowCopy["similar_geography"] = common;
                        return;
                    }
                    else if (r[d.column] !== "" && r[d.column].split(",").length > 0) {
                        rowCopy.similar_geography = `${r[d.column].split(",").length} ${d.field}`
                        return;
                    }
                });

                addedData.push(rowCopy);
            });

            setAdditionalReblRuleInfo(addedData);
        }
    }, [reblRuleData]);

    useEffect(() => {
        if (!isFetchingReblRules) {
            let filteredData = additionalReblRuleInfo
            searchValue.toLowerCase().split(" ").forEach(sv => {
                filteredData = filteredData.filter((reblRule) => {
                    return (
                        reblRule.action.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.adjustment_note.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.created_at.indexOf(sv) >= 0 ||
                        reblRule.creator_email.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.dates_active.indexOf(sv) >= 0 ||
                        reblRule.dates_affected.indexOf(sv) >= 0  ||
                        reblRule.flag.toString().indexOf(sv) >= 0 ||
                        reblRule.id.toString().indexOf(sv) >= 0 ||
                        reblRule.priority.toString().indexOf(sv) >= 0 ||
                        reblRule.strategy.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.search_geography.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.season.toLowerCase().indexOf(sv) >= 0 ||
                        reblRule.updater_email.toLowerCase().indexOf(sv) >= 0
                    )
                })
            })

            filteredData = !hideExpired ? filteredData : filteredData.filter(
                (r) => {
                    // Ternary to decide which end date to use. Events: stay-date. Adjustments: booking-date
                    let endDate = new Date(r.active_date_end )
                    return endDate > new Date();
                }
            )

            // sort
            filteredData = filteredData.sort((a, b) => b.id - a.id);

            setFilteredReblRules(filteredData)
        }
    }, [additionalReblRuleInfo, searchValue, hideExpired])

    const builder = new DataSourceBuilder<ReblRuleset>();

    builder.addColumn({
        label: 'Rule ID',
        field: 'id',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({
        label: 'Priority',
        field: 'priority',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.5
        },
    });
    builder.addColumn({
        label: 'User',
        field: 'creator_email',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.75
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (

                    <div>
                        {row.creator_email.replace("@vacasa.com", "")}
                    </div>
                )
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Geography',
        field: 'similar_geography',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 1.25
        },
        func: (row) => (
            <Tooltip message={`${row.search_geography}`}>
                <div>
                    <Icon.AlertCircle height={14} width={14}/>
                    {row.similar_geography}
                </div>
            </Tooltip>
        ),
    });
    builder.addColumn({
        label: 'Active Dates',
        field: 'dates_active'
    });
    builder.addColumn({
        label: 'Stay Dates',
        field: 'dates_affected'
    });
    builder.addColumn({
        label: 'Adjustment Note',
        field: 'adjustment_note',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 1.25
        },
        fieldConfiguration: {
            customLeftComponent: (row) => {
                return (
                    <div>{row.adjustment_note.length > 65 ?
                        <Tooltip message={`${row.adjustment_note}`}>
                            <div className={"long-table-cell"}>
                                <Icon.AlertCircle height={14} width={14}/>
                                {row.adjustment_note.substring(0,60)}
                                {row.adjustment_note.length > 60 ? "..." : ""}
                            </div>
                        </Tooltip>
                        :
                        <>{row.adjustment_note}</>
                    }</div>
                )
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Strategy',
        field: 'strategy',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.8
        },
    });
    builder.addColumn({
        label: 'Active',
        field: 'is_active',
        displayConfiguration: {
            justifyContent: 'left',
            flexGrow: 0.4
        },
        fieldConfiguration: {
            customLeftComponent: (row: ReblRuleset) => {
                if (!row.is_active) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            },
            format: () => <></>
        }
    });
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {
            justifyContent: 'right',
            flexGrow: 0.4
        },
        func: (row) => (
            <div className="action-button-list">
                <div className="action-button-icon" onClick={() => copyReblRule(row)}>
                    <Icon.Copy height={24} width={24}/>
                </div>
                <div className="action-button-icon" onClick={() => selectReblRule(row)}>
                    <Icon.Edit3 height={24} width={24}/>
                </div>
            </div>
        ),
    });

    builder.setSortable({field: 'id', order: 'desc'});
    builder.setFilterHeader({
        options: [
            {field: "is_active", values: ['ALL', true, false], type: "select"},
            {field: "priority", type: "range",  options: "int"},
        ],
        initialFilters: [
            { field: "is_active", value: "true", type: "boolean"},
        ]
    });

    builder.addPagination({remote: false});

    return (
        <div>
            <div className="rebl-utility-row">
                <div className="rebl-alert-container">
                    {uiAlert && (
                        <AlertMessage
                            customClass="alert-message"
                            text={uiAlert?.content}
                            type={uiAlert?.type}
                            height="small"
                        />
                    )}
                </div>
                <div className="rebl-search-input">
                    <label htmlFor="rebl-search">Search:</label>
                    <Input
                        customClass="rebl-search"
                        type="text"
                        value={searchValue}
                        placeholder="Search Rules..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                </div>
                <Tooltip message="Hide expired rules">
                    <div className="rebl-input-group">
                        <Checkbox
                            checked={hideExpired}
                            onChange={() => setHideExpired(!hideExpired)}
                            className="filter-cb"
                            id="expired-cb"
                        />
                        <label htmlFor="expired-cb">Hide Expired</label>
                    </div>
                </Tooltip>
                <div className="refetch-icon">
                    <Tooltip message="Refresh Units">
                        <Icon.RefreshCCW className="demand-influence-icon" height={24} width={24} onClick={() => {
                            refetch();
                            setUiAlert({type: "success", content: "Table is in-sync"});
                        }}/>
                    </Tooltip>
                    <Tooltip message="Add REBL Ruleset">
                         <span className="demand-influence-icon" onClick={newReblRule}>
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                    {withTooltip(
                        'Upload File',
                        <div
                            className="demand-influence-icon"
                            style={{display: "none"}}
                        >
                            <Icon.Upload height={24} width={24} onClick={() => setIsShowingSave(true)}/>
                        </div>,
                        {placement: 'top'}
                    )}
                    {isShowingSave && (
                        <BulkSidePanel
                            // hasChangeReason={true}
                            onClose={() => setIsShowingSave(false)}
                            // type={'cohort'}
                            title={'Bulk Rate Override'}
                            onSave={handleSave}
                        />
                    )}
                </div>
            </div>
            <div className="rebl-list">
                <div>
                    {isFetchingCohorts || isFetchingReblRules
                        ? <Loading className="rebl-list-loading"/>
                        : <VirtualizedTable
                            className="rebl-list-table"
                            dataSource={builder.build(filteredReblRules)}
                            onRowChange={() => null}
                            isReblAccordion={{
                                handleUiAlert: (message: Message) => setUiAlert(message)
                            }}
                            headerOptions={{height: 60}}
                        />
                    }
                </div>
            </div>
            <div className="modal">
                <Modal
                    canExit={true}
                    showModal={openModal}
                    setShowModal={setOpenModal}
                    size='large'
                >
                    <ReblRulesetModal
                        closeModal={(refresh?: boolean) => {
                            setOpenModal(false);
                            if (refresh === true) refetch();
                        }}
                        cohorts={cohortData}
                        locationOptions={locationOptions}
                        isFetchingLocationOptions={isFetchingLocationOptions}
                        formType={formType}
                        selectedReblRule={selectedReblRule}
                        // onUpdateReblRule={onUpdateReblRule}
                        // currentUser={currentUser}
                    />
                </Modal>
            </div>
        </div>
    );
};
