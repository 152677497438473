import {Cohort} from "../types";
import {apiCohort} from "./api";
import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "./store";
import {RAAssignment} from "../types/RAAssignment";


const cohortAdapter = createEntityAdapter<Cohort>({
    selectId: (cohort) => cohort.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState: EntityState<Cohort> = cohortAdapter.getInitialState();

export const cohortApi = apiCohort.injectEndpoints({
    endpoints: (builder) => ({
        getAllCohorts: builder.query<Cohort[], void>({
            query: () => "cohorts",
            providesTags: [{type: "Cohorts", id: "cohortList"}]
        }),
        updateRAAssignment: builder.mutation<void, RAAssignment>({
            query: (body) => ({
                url: `update_ra_assignment`,
                method: "POST",
                body: JSON.stringify(body)
            }),
        })
    }),
    overrideExisting: false,
});

export const cohortSlice = createSlice({
    name: "cohorts",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<Cohort[]>) => {
            cohortAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            cohortApi.endpoints.getAllCohorts.matchFulfilled, (state, {payload}) => {
                cohortAdapter.upsertMany(state, payload);
            })
    }
});

export const {
    useGetAllCohortsQuery,
    useUpdateRAAssignmentMutation,
} = cohortApi;
export const cohortSelector = (state: AppState) => state.cohorts;

export default cohortSlice.reducer;