import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './Tables.scss';
import {TableRowProps} from "react-virtualized";
import {DemandInfluenceFormOptions, HolidayEvent} from "../../types";
import {Icon, withTooltip } from '@vacasa/react-components-lib';
import {CompressedCell} from "../Common/CompressedCell/CompressedCell";

interface HolidayAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    openModal: (id: number, formType: DemandInfluenceFormOptions) => void;
}

export const HolidayAccordion: React.FC<HolidayAccordionProps> = (props) => {

    const {
        tableProps,
        expanded,
        handleChange,
        openModal,
    } = props;
    const [holidayEvents, setHolidayEvents] = useState<HolidayEvent[]>();

    const styles = {
        ...tableProps.style
    };

    useEffect(() => {
        const extraDetailsFromData: HolidayEvent[] = tableProps.rowData.holiday_events;
        setHolidayEvents(extraDetailsFromData);
    }, [tableProps.rowData.holiday_events]);

    return (
        <div key={tableProps.rowData.id} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.id} expanded={expanded === tableProps.rowData.id}
                          onChange={handleChange(tableProps.rowData.id, tableProps.index)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                    {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th className={"id-col"}>ID</th>
                            <th className={"title-col"}>Title</th>
                            <th className={"active-col"}>Active</th>
                            <th className={"date-range-col"}>Date Range</th>
                            <th className={"date-range-col"}>Comparison Date Range</th>
                            <th className={"id-col"}>Min Stay</th>
                            <th className={"id-col"}>Priority</th>
                            <th className={"address-col"}>Address</th>
                            <th className={"geography-col"}>Geography</th>
                            <th className={"email-col"}>Created</th>
                            <th className={"email-col"}>Creator</th>
                            <th className={"actions-col"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!holidayEvents ? holidayEvents.map(e => (
                                <tr className={"table-body"}>
                                    <td>{e.id}</td>
                                    <td><CompressedCell valueString={e.title} widthPct={12}/></td>
                                    <td>{e.active ?
                                        <Icon.CheckSquare className="icon-success" height={24} width={24}/>
                                        :
                                        <Icon.XCircle className="icon-danger" height={24} width={24}/>
                                    }</td>
                                    <td><CompressedCell valueString={e.date_range} widthPct={10}/></td>
                                    <td><CompressedCell valueString={e.comparison_date_range} widthPct={10}/></td>
                                    <td>{e.min_stay}</td>
                                    <td>{e.priority}</td>
                                    <td><CompressedCell valueString={e.address} widthPct={13}/></td>
                                    <td>{
                                        withTooltip(
                                            e.geography_tooltip,
                                            <div>
                                                <Icon.AlertCircle height={16} width={16}/>
                                                {e.similar_geography}
                                            </div>,
                                            { placement: 'bottom' }
                                        )
                                    }</td>
                                    <td>{e.created_at.split(" ")[0]}</td>
                                    <td>{e.creator_email.replace("@vacasa.com", "")}</td>
                                    <td>{
                                        <div className="action-button-list">
                                            {
                                                withTooltip(
                                                    "Copy event",
                                                    <div className="action-button action-button-icon"
                                                         onClick={() => {
                                                             openModal(e.id, DemandInfluenceFormOptions.COPY);
                                                         }}>
                                                        <Icon.Copy height={24} width={24}/>
                                                    </div>
                                                )
                                            }
                                            {
                                                withTooltip(
                                                    "Edit event",
                                                    <div className="action-button action-button-icon"
                                                         onClick={() => {
                                                             openModal(e.id, DemandInfluenceFormOptions.EDIT);
                                                         }}>
                                                        <Icon.Edit3 height={24} width={24}/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }</td>
                                </tr>
                            ))

                            :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
