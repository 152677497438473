import React, {ReactElement, useState} from 'react';
import {Loading, VirtualizedTable} from '../index';
import './CohortList.scss';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {Cohort, CurrentUser} from "../../types";
import {Icon, Modal, Tooltip} from '@vacasa/react-components-lib';
import {CohortAssignmentModal} from "./CohortAssignmentModal";
import {UiUtils} from "../../utils";


interface CohortListProps {
    cohortData: Cohort[];
    isFetchingCohorts: boolean;
    onRefresh: () => void;
    onUpdateCohort: (cohort: Cohort) => void;
    currentUser: CurrentUser;
}

type CohortListComponent = (props: CohortListProps) => ReactElement<any, any> | null;

export const CohortList: CohortListComponent = (props) => {
    const {cohortData, isFetchingCohorts, onUpdateCohort, currentUser} = props;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedCohort, setSelectedCohort] = useState<Cohort>();
    const isApprover = currentUser?.approver;
    
    const selectCohort = (cohort: Cohort) => {
        setSelectedCohort(cohort);
        setOpenModal(true);
    }

    const builder = new DataSourceBuilder<Cohort>();

    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {flexGrow: 0.5, justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Name',
        field: 'name',
        displayConfiguration: {flexGrow: 1.8, justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Units',
        field: 'unit_count',
        displayConfiguration: {flexGrow: 0.3, justifyContent: 'right'}
    });

    builder.addColumn({
        label: 'Active',
        field: 'active',
        displayConfiguration: {flexGrow: 0.3, justifyContent: 'right'},
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.active) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            }, format: () => <></>
        }
    });
    builder.addColumn({
        label: 'State',
        field: 'state',
        displayConfiguration: {flexGrow: 0.5, justifyContent: 'right'}
    });
    builder.addColumn({label: 'Region', field: 'region', displayConfiguration: {justifyContent: 'right'},});
    builder.addColumn({label: 'Manager', field: 'manager', displayConfiguration: {justifyContent: 'right'}});
    builder.addColumn({label: 'Analyst', field: 'analyst', displayConfiguration: {justifyContent: 'right'}});
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {flexGrow: 0.5},
        func: (row) => (
            <React.Fragment>
                <div className="action-button-list">
                    {isApprover ?
                        <Tooltip message={
                            "Change analyst assignment"
                        }>
                            <div className="action-button action-button-icon"
                                 onClick={() => {
                                     selectCohort(row)
                                 }}>
                                {
                                    row.loading
                                        ?
                                        <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                        :
                                        <Icon.Edit height={24} width={24}/>
                                }
                            </div>
                        </Tooltip>
                        :
                        <></>
                    }
                </div>
            </React.Fragment>
        )
    });

    builder.setSortable({field: 'name', order: 'asc'});
    builder.setFilterHeader({
        options: [
            { field: "active", values: ['ALL', true, false], type: "select"},
            { field: "unit_count", type: "range", options: "int"},
            { field: "state", values:  UiUtils.getStateOptions(cohortData), type: "select"},
            { field: "region", values:  UiUtils.getRegionOptions(cohortData), type: "select"},
            { field: "manager", values:  UiUtils.getManagerOptions(cohortData), type: "select"},
            { field: "analyst", values:  UiUtils.getAnalystOptions(cohortData), type: "select"},
        ],
        initialFilters: [
            { field: "active", value: "true", type: "boolean"}
        ]
    });

    builder.addPagination({remote: false});


    return (
        <div>
            <div className="cohort-list">
                <div>
                    {isFetchingCohorts
                        ? <Loading className="cohort-list-loading"/>
                        : <VirtualizedTable
                            className="cohort-list-table"
                            dataSource={builder.build(cohortData)}
                            onRowChange={() => null}
                            headerOptions={{height: 60}}
                        />
                    }
                </div>
            </div>

            <Modal
                canExit={true}
                showModal={openModal}
                setShowModal={setOpenModal}
                size='medium'
            >
                <CohortAssignmentModal
                    closeModal={() => setOpenModal(false)}
                    selectedCohort={selectedCohort}
                    onUpdateCohort={onUpdateCohort}
                    viewOnly={!isApprover}
                />
            </Modal>
        </div>
    );
};
