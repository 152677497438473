import React, {useEffect, useState} from 'react';
import {Loading} from '../index';
import './ReblRulesetModal.scss';
import {
    Cohort,
    // CurrentUser,
    DemandInfluenceFormOptions,
    DemandInfluenceFormType, LocationOptions,
    ReblComponent,
    ReblRuleset,
    SelectOption,
} from "../../types";
import {ButtonGroup, DatePicker, Icon, Select, Tooltip} from '@vacasa/react-components-lib';
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {DATE_FORMAT, UiUtils} from "../../utils";
import {ReblSection} from "../../types/ReblSection";
import {Checkbox} from '@material-ui/core';
import {addDays, format} from "date-fns"
import {useGetReblOptionsQuery, useAddReblRuleMutation, useUpdateReblRuleMutation} from "../../store";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


interface ReblRulesetModalProps {
    selectedReblRule: ReblRuleset;
    cohorts: Cohort[];
    formType: DemandInfluenceFormType;
    closeModal: (refresh?: boolean) => void;
    // onUpdateReblRule: (rule: ReblRuleset) => void;
    // currentUser: CurrentUser;
    locationOptions: LocationOptions,
    isFetchingLocationOptions: boolean,
}

export const ReblRulesetModal: React.FC<ReblRulesetModalProps> = (props) => {
    const {
        selectedReblRule,
        cohorts,
        formType,
        closeModal,
        // onUpdateReblRule,
        // currentUser,
        locationOptions,
        isFetchingLocationOptions
    } = props
    const {data: reblOptions, isFetching: isFetchingReblOptions} = useGetReblOptionsQuery();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isCompoundable, setIsCompoundable] = useState<boolean>(false);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [rules, setRules] = useState<string[]>([]);
    const [rulesetComponents, setRulesetComponents] = useState<ReblComponent[]>([]); // = UiUtils.getRulesetComponents(cohorts);
    const [selectedREBLComponent, setSelectedREBLComponent] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [updatedRule, setUpdatedRule] = useState<ReblRuleset>()
    const [validationErrorMsg, setValidationErrorMsg] = useState<string>("");
    const [validationWarningMsg, setValidationWarningMsg] = useState<string>("");
    // endpoints
    const [addRuleset] = useAddReblRuleMutation();
    const [updateRuleset] = useUpdateReblRuleMutation();
    const today = format(new Date(new Date().toISOString().split("T")[0]), DATE_FORMAT);
    const tomorrow = format(addDays(new Date(new Date().toISOString().split("T")[0]), 1), DATE_FORMAT);

    const getValue = (key: string, type?: string) => {
        if (key === "category") {
            const categories = UiUtils.levelCategories.sort((a, b) => a.threshold - b.threshold);
            const priority = !!updatedRule["priority"] ? updatedRule["priority"] : 1;
            let selectedCategory = categories[0].category
            for (const category of categories) {
                if (priority > category.threshold) {
                    selectedCategory = category.category
                }
            }
            return selectedCategory
        }

        if (!!updatedRule && updatedRule[key] !== undefined && updatedRule[key] !== null) {
            if (type === "date") {
                return format(new Date(updatedRule[key].split("-")), DATE_FORMAT)
            }
            else if (type === "multiselect") {
                const multiSelectValue = updatedRule[key].split(',').map((k: string) => UiUtils.getSelectOption(k));
                if (multiSelectValue.length === 1 && multiSelectValue[0]?.value === "") return [];
                return multiSelectValue;
            }
            return updatedRule[key];
        }
        else if (type === "multiselect") return []
        else if (type === "select") return ""
        else return null;
    }

    const setValue = (key: string, value: string | number | boolean, min?: number, max?: number) => {
        let updatedRuleCopy = {...updatedRule}

        if(!!min && value < min) value = min;
        if(!!max && value > max) value = max;

        if(key === "category") {
            for (const category of UiUtils.levelCategories) {
                if (value === category.category) {
                    updatedRuleCopy["priority"] = category.threshold
                }
            }
        }
        else if (key === "priority") {
            for (const category of UiUtils.levelCategories.sort((a, b) => a.threshold - b.threshold)) {
                if (value >= category.threshold) {
                    updatedRuleCopy["category"] = category.category;
                }
            }
        }

        updatedRuleCopy[key] = value
        setUpdatedRule(updatedRuleCopy)
    }

    const getOptions = (c: any) => {
        if (c.hasOwnProperty("options")) return c.options;

        const matchingKey = Object.keys(UiUtils.reblOptionsMap).filter(k => UiUtils.reblOptionsMap[k].key === c.key)[0];
        if (!matchingKey) return [];

        const optionMap = UiUtils.reblOptionsMap[matchingKey];
        let options = reblOptions[optionMap.plural].filter((o: any) => o.active);
        if (optionMap.extras) {
            optionMap.extras.forEach((column: string) => {
                if (column === "compoundable") {
                    options = options.filter((o: any) => isCompoundable ? o.compoundable : true);
                }
            })
        }

        options = options.map((o: any) => o[optionMap.key]);
        if (!optionMap.required) options.unshift("");

        return options.map((o: string) => UiUtils.getSelectOption(o));
    }

    const selectREBLComponent = (rc: ReblComponent) => {
        if (!!selectedREBLComponent && rc.title === selectedREBLComponent.title) {
            // collapse when clicking selected
            setSelectedREBLComponent(null);
        }
        else setSelectedREBLComponent(rc);
    }

    const selectSection = (s: ReblSection) => {
        if (!!selectedSection && s.title === selectedSection.title) {
            // collapse when clicking selected
            setSelectedSection(null)
        }
        else setSelectedSection(s);
    }

    const handleDateChange = async (value: Date, dateType: string, start_key: string, end_key: string) => {
        let updatedRuleCopy = {...updatedRule}

        if (dateType === "start") {
            updatedRuleCopy[start_key] = format(value, DATE_FORMAT)

            let endDate =  new Date(updatedRule[end_key].split("-"))
            // Usually start is picked first. If so, check the end date and push it out
            if (value >= endDate) {
                endDate = addDays(value, 1);
                updatedRuleCopy[end_key] = format(endDate, DATE_FORMAT)
            }
        }
        else {
            updatedRuleCopy[end_key] = format(value, DATE_FORMAT)
        }
        setUpdatedRule(updatedRuleCopy)
    }

    const handleDayChange = async (value: number, dayType: string, start_key: string, end_key: string, min: number, max: number) => {
        let updatedRuleCopy = {...updatedRule};
        let endValue = updatedRule[end_key];
        if (dayType === "start") {
            updatedRuleCopy[start_key] = value;

            // Usually start is picked first. If so, check the end value and push it out
            if (value >= endValue) {
                endValue = value + 1;
                updatedRuleCopy[end_key] = endValue;
            }
        }
        else {
            updatedRuleCopy[end_key] = value
        }

        setUpdatedRule(updatedRuleCopy)
    }

    const getValidMessages = () => {
        let errorMessages: string[] = []
        let warningMessages: string[] = []
        rulesetComponents.forEach((rc: ReblComponent) => {
            rc.sections.forEach((s: ReblSection) => {
                s.components.forEach((c: any) => {
                    if (c.type === "date_range") {
                        if (
                            (!!updatedRule[c.start_key] && !updatedRule[c.end_key])
                            ||
                            (c.required && !updatedRule[c.end_key])
                        ) {
                            errorMessages.push(`missing ${c.end_key}`);
                        }
                        else if (
                            (!updatedRule[c.start_key] && !!updatedRule[c.end_key])
                            ||
                            (c.required && !updatedRule[c.start_key])
                        ) {
                            errorMessages.push(`missing ${c.start_key}`);
                        }
                        else if (
                            !!updatedRule[c.start_key] &&
                            !!updatedRule[c.end_key] &&
                            !UiUtils.isValidDateRange(updatedRule[c.start_key], updatedRule[c.end_key])
                        ) {
                            errorMessages.push(`Invalid ${c.label} range`);
                        }
                    }
                    else if (c.type === "days_range") {
                        if (!!updatedRule[c.start_key] && !updatedRule[c.end_key] && c.max !== null) errorMessages.push(`missing ${c.end_key}`);
                        else if (!updatedRule[c.start_key] && !!updatedRule[c.end_key]) errorMessages.push(`missing ${c.start_key}`);
                        else if (updatedRule[c.start_key] > updatedRule[c.end_key]) errorMessages.push(`${c.start_key} must be less than ${c.end_key}`)
                    }
                    else if (c.required && c.type !== "boolean") {
                        if (!updatedRule[c.key] || updatedRule[c.key] === "") {
                            errorMessages.push(`${c.label} is required`)
                        }
                    }
                    else if (
                        rc.type === "rules" &&
                        rules.indexOf(c.label) >= 0 && (
                            updatedRule[c.key] === null ||
                            updatedRule[c.key] === undefined ||
                            updatedRule[c.key] === "" ||
                            (c.type === "number" && isNaN(updatedRule[c.key]))
                        )
                    ) {
                        const msg = `Please provide a value for ${c.label}`;
                        if (c.required) errorMessages.push(msg);
                        else warningMessages.push(msg);
                    }
                })
            })
        })

        if (updatedRule.compoundable) {
            const selectedAction = reblOptions.actions.filter(a => a.action === updatedRule.action)[0];
            if (!!selectedAction && !selectedAction.compoundable) {
                errorMessages.push("Selected action is not compoundable");
            }
        }

        return {errors: errorMessages, warnings: warningMessages}
    }

    const changeCheckedRules = (c: any) => {
        let rulesCopy = [...rules];
        let updatedRuleCopy = {...updatedRule}
        
        const idx = rulesCopy.indexOf(c.label);
        if (idx >= 0) { // was checked, now unchecked: unset values
            rulesCopy.splice(idx, 1)
            if (["days_range", "date_range"].indexOf(c.type) >= 0) {
                updatedRuleCopy[c.start_key] = "";
                updatedRuleCopy[c.end_key] = "";
            }
            else if (c.type === "multiselect") {
                updatedRuleCopy[c.key] = null;
            }
            else {
                updatedRuleCopy[c.key] = "";
            }
        }
        else {
            rulesCopy.push(c.label)
            if (c.type === "date_range") {
                updatedRuleCopy[c.start_key] = today;
                updatedRuleCopy[c.end_key] = tomorrow;
            }
        }
        setUpdatedRule(updatedRuleCopy)
        setRules(rulesCopy);
    }

    const handleSave = async () => {
        let updatedRuleCopy = {...updatedRule};

        rulesetComponents.forEach((rc: ReblComponent) => {
            rc.sections.forEach((s: ReblSection) => {
                s.components.forEach((c: any) => {
                    if (["date_range", "days_range"].indexOf(c.type) >= 0) {
                        if (!updatedRuleCopy[c.start_key]) updatedRuleCopy[c.start_key] = null;
                        if (!updatedRuleCopy[c.end_key]) updatedRuleCopy[c.end_key] = null;
                    }
                    else if (c.type === "boolean") updatedRuleCopy[c.key] = !!updatedRuleCopy[c.key];
                    else if (updatedRuleCopy[c.key] === "" || updatedRule[c.key] === undefined) updatedRuleCopy[c.key] = null;
                })
            })
        })
        Object.keys(updatedRuleCopy).forEach((key) => {
            if (updatedRuleCopy[key] === "") {
                updatedRuleCopy[key] = null;
            }
        });

        setIsSaving(true);

        if (formType === DemandInfluenceFormOptions.EDIT) {
            await updateRuleset({
                id: selectedReblRule.id,
                data: updatedRuleCopy
            }).then((response) => {
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                setIsSaving(false);
                closeModal(true);
            })
        }
        else { // ADD and COPY
            await addRuleset(updatedRuleCopy).then((response) => {
                setIsSaving(false);
                if (!!response["error"]) {
                    console.log(response["error"]);
                }
                else {
                    closeModal(true);
                }
            })
        }
    }

    const cancelButton: ButtonProps = {
        onClick: () => {
            closeModal()
        },
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children:
            isSaving
                ? <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                : "Save",
        variant: "secondary",
        disabled: disableSave || isSaving,
        customClass: 'button-group'
    }


    useEffect(() => {
        if (
            !!locationOptions &&
            !isFetchingLocationOptions &&
            !!reblOptions &&
            !isFetchingReblOptions
        ) {
            setRulesetComponents(UiUtils.getRulesetComponents(cohorts, locationOptions))
        }
    }, [isFetchingLocationOptions, locationOptions, isFetchingReblOptions, reblOptions])

    useEffect(() => {
        if (!!updatedRule && !isFetchingReblOptions) {
            if (updatedRule.compoundable !== undefined && isCompoundable !== updatedRule.compoundable) setIsCompoundable(!isCompoundable);

            const messageObj = getValidMessages();
            const errorMessages = messageObj.errors;
            const warningMessages = messageObj.warnings;

            if (errorMessages.length > 0) setValidationErrorMsg(
                `${errorMessages.length} error(s); ${errorMessages[0]} `
            )
            else {setValidationErrorMsg("")}

            if (warningMessages.length > 0) setValidationWarningMsg(
                `${warningMessages.length} warning(s); ${warningMessages[0]} `
            )
            else {setValidationWarningMsg("")}

            setDisableSave(errorMessages.length > 0)
        }
    }, [updatedRule, isFetchingReblOptions]);

    useEffect(() => {
        if (!!selectedReblRule) setUpdatedRule(selectedReblRule)
        else {
            // setUpdatedRule(new ReblRuleset())
        }
    }, [selectedReblRule]);

    useEffect(() => {
        if (!!rulesetComponents && rulesetComponents.length > 0 && (!!updatedRule || !selectedReblRule)){
            if (isLoading) {
                if (formType === DemandInfluenceFormOptions.EDIT) {
                    setSelectedREBLComponent(rulesetComponents[1])
                }
                else {
                    setSelectedREBLComponent(rulesetComponents[0])
                }

                let tempRules = [];
                let updatedRuleCopy = {...updatedRule}; // let's set some defaults for better UX
                rulesetComponents.forEach((rc) => {
                    rc.sections.forEach((s: ReblSection) => {
                        s.components.forEach((c: any) => {
                            if(!selectedReblRule) {
                                if (["date_range"].indexOf(c.type) >= 0) {
                                    updatedRuleCopy[c.start_key] = today;
                                    updatedRuleCopy[c.end_key] = tomorrow;
                                }
                                else if (!!c.default) {
                                    updatedRuleCopy[c.key] = c.default;
                                }

                                if (c.required || !!c.default) tempRules.push(c.label);
                            }
                            else if (rc.type === "rules") {
                                if (c.required) {
                                    tempRules.push(c.label);
                                } else if (["date_range", "days_range"].indexOf(c.type) >= 0) {
                                    if (!!updatedRule[c.start_key]) tempRules.push(c.label)
                                } else {
                                    if (updatedRule[c.key] !== "") tempRules.push(c.label);
                                }
                            }
                        })
                    })
                });
                setRules(tempRules);
                if (!selectedReblRule) {
                    setUpdatedRule(updatedRuleCopy);
                }
            }
        }
    }, [rulesetComponents, updatedRule]);

    useEffect(() => {
        if (!!selectedREBLComponent) {
            if (!selectedSection || selectedREBLComponent?.sections.indexOf(selectedSection) < 0) {
                setSelectedSection(selectedREBLComponent?.sections[0])
            }
        }
    }, [selectedREBLComponent]);

    useEffect(() => {
        if (!!selectedSection){
            setIsLoading(false);
        }
    }, [selectedSection]);

    const getComponent = (c: any) => {
        return (
            <>
                <div className={"rebl-row"}>
                    <div className={`rebl-input-group ${c.type === "boolean" ? "cb-row" : ""}`}>
                        {!c.required && !c.default && selectedREBLComponent?.type !== "metadata" &&
                            <div className="rebl-tooltip">
                                <Tooltip message={"remove rule"}>
                                    <Icon.XCircle className={"pointer"} height={16} width={16}
                                                onClick={() => changeCheckedRules(c)}/>
                                </Tooltip>
                            </div>
                        }
                        {(c.required || c.default || selectedREBLComponent?.type === "metadata") && <div className={"spacer"}></div>}
                        {c.required && <p style={{color: "red", width: "20px"}}>&nbsp;*</p>}
                        {!c.required && <div className="spacer"></div>}
                        <label className={c.label.length > 25 && "rebl-label-decreased-font"}>{c.label}</label>
                        {c.type === "text" &&
                            <input
                                value={getValue(c.key)}
                                    type={"text"}
                                    onChange={(e) => setValue(c.key, e.target.value)}
                                    disabled={c.disabled}
                                />
                        }
                        {c.type === "boolean" &&
                            <Checkbox
                                checked={getValue(c.key)}
                                onChange={(e) => setValue(c.key, !getValue(c.key))}
                            />
                        }
                        {(c.type === "number" || c.type === "priority") &&
                            <input
                                value={getValue(c.key)}
                                type={"number"}
                                min={c.min}
                                max={c.max}
                                step={c.step}
                                disabled={c.disabled}
                                onChange={(e) => setValue(c.key, e.target.valueAsNumber, c.min, c.max)}
                                className={"rebl-number-input"}
                                style={{width: "300px"}}
                                required
                                placeholder={`Between ${c.min} and ${c.max}`}
                            />
                        }
                        {c.type === "select" &&
                            <Select
                                value={getValue(c.key, c.type)}
                                options={getOptions(c)}
                                onChange={(e) => setValue(c.key, e.target.value)}
                                disabled={c.disabled}
                            />
                        }
                        {c.type === "textarea" &&
                            <textarea
                                rows={3}
                                maxLength={512}
                                value={getValue(c.key)}
                                onChange={(e) => setValue(c.key, e.target.value)}
                                disabled={c.disabled}
                                placeholder={c.placeholder}
                            ></textarea>
                        }
                        {c.type === "date_range" &&
                            <>
                                <DatePicker
                                    variant="inline"
                                    value={getValue(c.start_key, "date")}
                                    onChange={(date: Date | null, value?: string | null) => handleDateChange(date, "start", c.start_key, c.end_key)}
                                    disableToolbar={true}
                                    inputValue={getValue(c.start_key, "date")}
                                    className={"date-input"}
                                />
                                &nbsp;to&nbsp;
                                <DatePicker
                                    variant="inline"
                                    value={getValue(c.end_key, "date")}
                                    onChange={(date: Date | null, value?: string | null) => handleDateChange(date, "end", c.start_key, c.end_key)}
                                    disableToolbar={true}
                                    inputValue={getValue(c.end_key, "date")}
                                    className={"date-input"}
                                />
                            </>
                        }
                        {c.type === "days_range" &&
                            <>
                                <input
                                    value={getValue(c.start_key)}
                                    type={"number"}
                                    min={c.min}
                                    max={c.max}
                                    step={c.step}
                                    disabled={c.disabled}
                                    onChange={(e) => handleDayChange(
                                        e.target.valueAsNumber,
                                        "start",
                                        c.start_key,
                                        c.end_key,
                                        c.min,
                                        c.max
                                    )}
                                    className={"rebl-day-number-input"}
                                />
                                &nbsp;to&nbsp;
                                <input
                                    value={getValue(c.end_key)}
                                    type={"number"}
                                    min={c.min}
                                    max={c.max}
                                    step={c.step}
                                    disabled={c.disabled}
                                    onChange={(e) => handleDayChange(
                                        e.target.valueAsNumber,
                                        "end",
                                        c.start_key,
                                        c.end_key,
                                        c.min,
                                        c.max
                                    )}
                                    className={"rebl-day-number-input"}
                                />
                            </>
                        }
                        {c.type === "multiselect" &&
                            <div>
                                <Autocomplete
                                    className={"rebl-auto-complete"}
                                    options={c.options}
                                    getOptionLabel={(option: SelectOption) => option.display}
                                    multiple={true}
                                    filterSelectedOptions={true}
                                    value={getValue(c.key, c.type)}
                                    onChange={(e, values) => {
                                        setValue(c.key, values.map(v => v.value).join(","));
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField {...params} variant="outlined"/>
                                        )
                                    }}
                                />
                            </div>

                        }
                    </div>
                </div>
                {c.type === "priority" &&
                    <div className="rebl-row">
                        <div className={"rebl-input-group"}>
                            <div className={"spacer"}></div>
                            <div className={"spacer"}></div>
                            <label>Category</label>
                            <Select
                                value={getValue("category")}
                                options={c.options}
                                onChange={(e) => setValue("category", e.target.value)}
                            />
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            <div className="form-header">
                <h4>Ruleset</h4>
            </div>
            <div className="form-body rebl-body">
                {isLoading
                    ? <Loading className="rebl-modal-loading"/>
                    : <div className="rebl-row">
                        <div className="rebl-col accordion-col">
                            {
                                rulesetComponents.map((rc: ReblComponent) => {
                                    return (
                                        <>
                                        <div className={`row rebl-accordion-row ${rc.title === selectedREBLComponent?.title ? "selected" : ""}`} onClick={() => {selectREBLComponent(rc)}}>
                                            {selectedREBLComponent?.title === rc.title ?
                                                <Icon.ChevronDown height={20} width={20}/>
                                             :
                                                <Icon.ChevronRight height={20} width={20}/>
                                            }
                                            <div>{rc.title}</div>
                                        </div>
                                        <div className="section-row">
                                            {rc.title === selectedREBLComponent?.title && rc.sections.map((s: ReblSection) => {
                                                return (
                                                    <>
                                                    <div onClick={() => {selectSection(s)}} className={s.title === selectedSection?.title ? "selected" : ""}>
                                                        {selectedSection?.title === s.title ?
                                                            <Icon.ChevronDown height={16} width={16}/>
                                                            :
                                                            <Icon.ChevronRight height={16} width={16}/>
                                                        }
                                                        {s.title}
                                                    </div>
                                                    <>
                                                    {selectedSection?.title === s.title && s.components.map((c: any) => {
                                                        return (
                                                            <div className="component-row">
                                                            <Checkbox
                                                                className={"small-cb"}
                                                                checked={rules.indexOf(c.label) >= 0 || rc.type === "metadata"}
                                                                onChange={() => changeCheckedRules(c)}
                                                                disabled={c.required || rc.type === "metadata"}
                                                                id={`cb-${c.label}`}
                                                            />
                                                                <label htmlFor={`cb-${c.label}`} style={{fontSize: "12px"}}>
                                                                    {c.label}
                                                                </label>
                                                            </div>
                                                        )})}
                                                    </>
                                                    </>

                                                )
                                            })}
                                        </div>
                                            {rc.type === "metadata" && <div style={{width: "200px"}}><hr/></div>}
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className="rebl-col rule-col">
                            {selectedREBLComponent?.type === "rules" && rules.map((rule) => (
                                <>
                                {rulesetComponents.map((rc: ReblComponent) => (
                                    <>
                                    {selectedREBLComponent?.type === rc.type && rc.sections.map((s: ReblSection) => (
                                            <>
                                            {s.components.map((c: any) => c.label === rule ? getComponent(c) : <></>)}
                                            </>
                                        )
                                    )
                                    }
                                    </>))
                                }
                                </>
                                )
                            )}
                            {selectedREBLComponent?.type === "metadata" &&
                                <div>
                                    {!!selectedReblRule && formType === DemandInfluenceFormOptions.EDIT &&
                                        <div className={"rebl-row"}>
                                            <div className="rebl-input-group">
                                                <div className="spacer"></div>
                                                <div className="spacer"></div>
                                                <label>ID: </label>
                                                <input type="number" value={selectedReblRule.id} disabled={true}/>
                                            </div>
                                        </div>
                                    }
                                    {selectedSection?.components.map((c: any) => getComponent(c))}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="form-footer" style={{height: "30px"}}>
                <span>
                    {
                        validationErrorMsg
                        ?
                        <p>{validationErrorMsg}</p>
                        :
                        validationWarningMsg
                        ?
                        <p>{validationWarningMsg}</p>
                        :
                        <></>
                    }
                </span>
                <div className="form-footer-buttons">
                    <ButtonGroup left={cancelButton} right={saveButton}/>
                </div>
            </div>
        </div>
    );
};
