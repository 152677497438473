import React, {useState} from 'react';
import './Loading.scss';

interface LoadingProps {
    className?: string;
}
export const Loading: React.FC<LoadingProps> = (props) => {
    const messages = [
        "Thank you for your patience",
        "Our VPN is Required to use this app",
        "Sorry for the delay",
        "VPN Required",
        "This is taking a moment",
        "VPN Required",
        "Sorry this is taking more than a while",
        "Connect to our VPN to use this app",
        "This app is private and requires VPN",
        "Sorry for the inconvenience",
        "Are you on VPN?",
        "VPN",
        "Why isn't she on VPN",
        "Do you think she even knows how to VPN?",
        "Are you still reading this?",
    ]
    const [messageIdx, setMessageIdx] = useState(0);
    const [showElement, setShowElement] = React.useState(false);
    const [message, setMessage] = React.useState(messages[messageIdx])


    setTimeout(() => {
        setShowElement(true);
    }, 5_000);

    React.useEffect(() => {
        setTimeout(() => {
            setMessageIdx(messageIdx + 1);
            setMessage(messages[messageIdx % messages.length])
        }, 5_000);

    }, [messageIdx])

    return (
        <div id="loading" className={`loading ${props.className ?? ''}`}>
            <div className={'loader-dots'}>
                <div className={'bounce1'}/>
                <div className={'bounce2'}/>
                <div className={'bounce3'}/>
                { showElement
                    ?
                    <span className={'vpn-required'}>
                        <p>{message}</p>
                    </span>
                    :
                    <></>
                }
            </div>
        </div>
    );
};
