import {AdminUser, CurrentUser, DemandInfluence} from "../types";
import {apiAdmin} from "./api";
import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "./store";
import {RAEmployee} from "../types/RAEmployee";


const adminAdapter = createEntityAdapter<AdminUser>({
    selectId: (user) => user.id,
    sortComparer: (a, b) => a.id - b.id
});

const initialState: EntityState<AdminUser> = adminAdapter.getInitialState();

export const adminApi = apiAdmin.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentUser: builder.query<CurrentUser, void>({
           query: () => "current_user",
           providesTags: [{type: "Admin", id: "currentUser"}]
        }),
        getAdmins: builder.query<AdminUser[], void>({
            query: () => "admin/get_admins",
            providesTags: [{type: "Admin", id: "adminList"}]
        }),
        addAdmin: builder.mutation<AdminUser, Partial<AdminUser>>({
            query: (body) => ({
                url: `admin/user`,
                method: "POST",
                body: JSON.stringify(body)
            }),
            invalidatesTags: [{type: 'Admin', id: 'addAdmin'}]
        }),
        updateAdmin: builder.mutation<AdminUser, { id: number; approver: boolean }>({
            query: ({id, approver}) => ({
                url: `admin/user/${id}`,
                method: "PUT",
                body: JSON.stringify({approver: approver, email: ""}),
            }),
            invalidatesTags: [{type: "Admin", id: "updateAdmin"}]
        }),
        removeAdmin: builder.mutation<AdminUser, {id: number}>({
            query: ({id}) => ({
                url: `admin/user/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{type: "Admin", id: "removeAdmin"}]
        }),
        approveDI: builder.mutation<DemandInfluence, { id: number; approval_status: string, reason: string}>({
            query: ({id, approval_status, reason}) => ({
                url: `admin/set_approval/${id}`,
                method: "POST",
                body: JSON.stringify({status: approval_status, reason: reason}),
            }),
            invalidatesTags: [{type: "Admin", id: "approveDI"}]
        }),
        getAnalysts: builder.query<RAEmployee[], void>({
            query: () => "admin/get_analysts",
            providesTags: [{type: "Admin", id: "analystList"}]  // TODO what do tags do?
        }),
        getManagers: builder.query<RAEmployee[], void>({
            query: () => "admin/get_managers",
            providesTags: [{type: "Admin", id: "managerList"}]  // TODO what do tags do?
        }),
    }),
    overrideExisting: false,
});

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<AdminUser[]>) => {
            adminAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(
            adminApi.endpoints.getAdmins.matchFulfilled, (state, {payload}) => {
                adminAdapter.upsertMany(state, payload);
            })
    }
});

export const {
    useGetCurrentUserQuery,
    useGetAdminsQuery,
    useAddAdminMutation,
    useUpdateAdminMutation,
    useRemoveAdminMutation,
    useApproveDIMutation,
    useGetAnalystsQuery,
    useGetManagersQuery,
} = adminApi;
export const adminSelector = (state: AppState) => state.adminList;

export default adminSlice.reducer;
