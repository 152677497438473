import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import {Button, Icon, Modal, Tabs, Tooltip} from '@vacasa/react-components-lib';
import {Configuration} from '../../Configuration';
import './Home.scss';
import {Admin, CohortList, DemandInfluenceList, Loading, ReblRulesetList, UnitList, MissingRates} from "../../components";
import {useHistory} from "react-router-dom";
import {Cohort} from "../../types";
import {useGetAllCohortsQuery, useGetCurrentUserQuery, useGetUnitLocationOptionsQuery} from "../../store";
import {isEmpty, sortBy} from "lodash";
import {UnitTetherTable} from "../../components/Tables/UnitTether";
import {HolidaysTable} from "../../components/Tables/Holidays";

export const Home: React.FC = () => {
    const search = window.location.search;
    const history = useHistory();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCohortID, setSelectedCohortID] = useState<string>("");
    const tabParam = params.get('tab');
    const cohortParam = params.get('sc');
    const [openAdminModal, setOpenAdminModal] = useState<boolean>(false);
    const [openMissingRatesModal, setOpenMissingRatesModal] = useState<boolean>(false);
    const [showNonProdWarning, setShowNonProdWarning] = useState<boolean>(true);

    // TODO: cache this
    const {refetch: refetchCohorts, data: cohortInfo, isFetching: isFetchingCohorts} = useGetAllCohortsQuery();
    const {data: currentUser, isFetching: isFetchingCurrentUser} = useGetCurrentUserQuery();
    const {data: locationOptions, isFetching: isFetchingLocationOptions} = useGetUnitLocationOptionsQuery();

    const [cohortData, setCohortData] = useState<Cohort[]>([]);

    function onUpdateCohort(updatedCohort: Cohort) {
        const updatedCohorts = cohortData.map(
            cohort => {
                if (cohort.id === updatedCohort.id) {
                    return updatedCohort
                } else {return cohort}
            }
        )
        setCohortData(updatedCohorts)
    }

    const tabs = [
        {
            id: "strategic_cohorts",
            label: "Cohorts",
            component: <CohortList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                onRefresh={refetchCohorts}
                onUpdateCohort={onUpdateCohort}
                currentUser={currentUser}
            />
        },
        {
            id: "demand_influences",
            label: "Demand Influences",
            component: <DemandInfluenceList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                selectedCohortID={selectedCohortID}
                currentUser={currentUser}
            />
        }, {
            id: "unit_assigner",
            label: "Units",
            component: <UnitList
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                cohortData={cohortData}
                currentUser={currentUser}
            />
        }, {
            id: "rebl",
            label: "REBL",
            component: <ReblRulesetList
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                isFetchingLocationOptions={isFetchingLocationOptions}
                cohortData={cohortData}
                // currentUser={currentUser}
                locationOptions={locationOptions}
            />
        }, {
            id: "tethering",
            label: "Tethering (WiP)",
            component: <UnitTetherTable/>
        }, {
            id: "holidays",
            label: "Holidays (WiP)",
             component: <HolidaysTable
                 isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                 cohortData={cohortData}
                 currentUser={currentUser}
                 locationOptions={locationOptions}
                 isFetchingLocationOptions={isFetchingLocationOptions}
             />
        }
    ];

    const [selectedTab, setSelectedTab] = useState<number>(
        Math.max(tabParam ? tabs.findIndex(idx => idx.id === tabParam) : 0, 0)
    );

    useEffect(() => {
        if(cohortParam) setSelectedCohortID(cohortParam);
    }, [cohortParam])

    useEffect(() => {
        if (!isEmpty(cohortInfo)) setCohortData(sortBy(cohortInfo, (cohort) => cohort.name));
    }, [cohortInfo, isFetchingCohorts])

    useEffect(() => {
        if (selectedTab !== null && selectedTab >= 0) {
            if (selectedTab !== 1) {
                setSelectedCohortID(null);
            }
            
            let newParams = new URLSearchParams();
            newParams.set("tab", tabs[selectedTab]["id"])
            if(selectedCohortID && selectedTab !== 0) {
                newParams.append("sc", selectedCohortID.toString());
            }

            history.push({
                pathname: window.location.pathname,
                search: "?" + newParams.toString()
            })

            setLoading(false);
        }
    }, [selectedTab, selectedCohortID])

    return (
        <Paper elevation={3} className="home">
            {!Configuration.isProduction && !Configuration.isLocal && showNonProdWarning &&
                <span className={"lower-env-banner"}>
                    <Tooltip message={"hide banner"} onClick={() => setShowNonProdWarning(false)} className={"pointer"}>
                        <Icon.XCircle height={24} width={24}/>
                    </Tooltip>
                    <h1>This is a training environment -- Changes will not be permanent</h1>
                </span>
            }
            <div className="tabs-container">
                { loading ? <Loading></Loading> :
                <>
                    {currentUser?.admin && <div style={{float: "right"}}>
                        <Button variant="secondary" onClick={() => {
                            setOpenAdminModal(true);
                            setOpenMissingRatesModal(false);
                        }}>
                            Open Admin
                        </Button>
                    </div>
                    }
                    {selectedTab === 2 &&
                        <div style={{float: "right", paddingRight: "5px"}}>
                            <Button variant="primary" onClick={() => {
                                setOpenAdminModal(false);
                                setOpenMissingRatesModal(true);
                            }}>
                                <Icon.Search height={16} width={16}/>
                                Missing Rates
                            </Button>
                        </div>
                    }
                    <Tabs
                        selected={selectedTab}
                        tabs={tabs}
                        onChange={(index) => setSelectedTab(index)}
                    />
                </>
                }
            </div>
            <Modal
                canExit={true}
                setShowModal={setOpenAdminModal}
                showModal={openAdminModal}
                size='large'
            >
                <Admin></Admin>
            </Modal>
            <Modal
                canExit={true}
                setShowModal={setOpenMissingRatesModal}
                showModal={openMissingRatesModal}
                size='medium'
            >
                <div>
                    <MissingRates/>
                </div>
            </Modal>
        </Paper>
    );
};